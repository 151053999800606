import React, { useCallback } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BackButton } from '@components/Base';
import HeaderSecondary from '@components/HeaderSecondary';
import { useCarQuotesResultPageParams } from '@components/CarQuoteResultPage';
import { getCarQuoteResultPageRoute } from '@components/CarQuoteResultPage/route';

import { useCarQuotes } from './hook';
import { TableInsurer } from './table-insurer';
import { TablePremium } from './table-premium';
import { TableCover } from './table-cover';
import { TableDeductible } from './table-deductible';
import { TableExclusion } from './table-exclusion';

const CarQuoteComparePage = () => {
  const navigate = useNavigate();
  const params = useCarQuotesResultPageParams();
  const carQuotes = useCarQuotes();

  if (!carQuotes) return null;

  const handleBack = useCallback(() => {
    navigate(getCarQuoteResultPageRoute(params), { replace: true });
  }, []);

  return (
    <>
      <HeaderSecondary />

      <Container className="mt-4">
        <BackButton onClick={handleBack} className="mb-4" />

        <Table>
          <tbody>
            <TableInsurer />
            <TablePremium />
            <TableCover />
            <TableDeductible />
            <TableExclusion />
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export { CarQuoteComparePage };
