import { useFormikContext } from 'formik';
import { isEmpty, some, isArray, keys } from 'lodash';

import { FormValues, MemberFormValues } from '../schema';

export const useUnqualifiedMembers = () => {
  const { values, errors } = useFormikContext<FormValues>();

  const isInvalid = ({ questionnaire }: MemberFormValues) => some(questionnaire, ['answer', 'yes']);

  let hasInvalidMembers = false;

  if (!isEmpty(errors)) {
    for (const key of keys(errors)) {
      const data = values[key as keyof FormValues];
      hasInvalidMembers = isArray(data) ? some(data, isInvalid) : isInvalid(data as MemberFormValues);
      if (hasInvalidMembers) break;
    }
  }

  return hasInvalidMembers;
};
