import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BackButton } from '@components/Base';
import { BenefitGroupTypes } from '@models/PersonalAccidentPlanBenefitGroup';
import HeaderSecondary from '@components/HeaderSecondary';
import { NoData, Loading, LoadingError } from '@components/Base';
import { usePersonalAccidentQuoteResultPageParams } from '@components/PersonalAccidentQuoteResultPage';
import { getPersonalAccidentQuoteResultPageRoute } from '@components/PersonalAccidentQuoteResultPage/route';

import { usePersonalAccidentQuotesFetch } from './hook';
import { TableInsurer } from './table-insurer';
import { TableAnnualLimit } from './table-annual-limit';
import { TableGeographicCoverage } from './table-geographic-coverage';
import { TableBenefit } from './table-benefit';
import { isUndefined, isNull } from 'lodash';

const PersonalAccidentQuoteComparePage = () => {
  const navigate = useNavigate();
  const params = usePersonalAccidentQuoteResultPageParams();
  const personalAccidentQuotes = usePersonalAccidentQuotesFetch();
  const isLoaded = !isUndefined(personalAccidentQuotes);
  const isError = isNull(personalAccidentQuotes);
  const hasPersonalAccidentQuotes = isLoaded && (personalAccidentQuotes ?? []).length > 0;

  const handleBack = () => {
    navigate(getPersonalAccidentQuoteResultPageRoute(params), { replace: true });
  };

  return (
    <>
      <HeaderSecondary />

      <Container className="mt-4">
        <BackButton onClick={handleBack} className="mb-4" />
        {!isLoaded && <Loading />}
        {isError && <LoadingError />}
        {isLoaded && !hasPersonalAccidentQuotes && <NoData />}

        {hasPersonalAccidentQuotes && (
          <Table>
            <tbody>
              <TableInsurer />
              <TableAnnualLimit />
              <TableGeographicCoverage />
              <TableBenefit groupType={BenefitGroupTypes.BasicBenefits} />
              <TableBenefit groupType={BenefitGroupTypes.Extensions} />
              <TableBenefit groupType={BenefitGroupTypes.Iema} />
            </tbody>
          </Table>
        )}
      </Container>
    </>
  );
};

export { PersonalAccidentQuoteComparePage };
