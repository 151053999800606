import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHref, useParams } from 'react-router-dom';

import { Article } from '@models/Article';
import { InsuranceTypes, tInsuranceType } from '@models/InsuranceType';

const ArticleBreadcrumb = ({ article }: { article?: Article }) => {
  const { t } = useTranslation();
  const { insuranceType } = useParams<{ insuranceType: InsuranceTypes }>();

  return (
    <Breadcrumb className="mt-5">
      <Breadcrumb.Item href={useHref('/')}>{t('common:home')}</Breadcrumb.Item>
      <Breadcrumb.Item href={useHref('/articles')} active={!insuranceType}>
        {t('common:articles')}
      </Breadcrumb.Item>
      {insuranceType && (
        <Breadcrumb.Item href={useHref(`/articles/${insuranceType}`)} active={!article}>
          {tInsuranceType(insuranceType)}
        </Breadcrumb.Item>
      )}
      {article && <Breadcrumb.Item active>{article.title}</Breadcrumb.Item>}
    </Breadcrumb>
  );
};

export { ArticleBreadcrumb };
