import React, { useState } from 'react';
import { useField } from 'formik';
import { Button, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { forEach } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ConfirmModal } from '@components/Base';
import { getFieldKeys } from '@utils/yup';

import { meFormValuesParamsSchema } from '../schema';

type Props = {
  name: string;
  label: string;
  removable?: boolean;
};

const ErrorItem = ({ removable = true, name, label }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [, , helpers] = useField(name);

  const handleRemove = () => {
    forEach(getFieldKeys(meFormValuesParamsSchema), (key: string) => searchParams.delete(`${name}[${key}]`));
    setSearchParams(searchParams);
    helpers.setValue(undefined);
    navigate(0);
  };

  return (
    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-center" data-testid="error-item">
      <div className="ms-2 me-auto">
        <strong className="fw-bold">{label}</strong>
      </div>
      {removable && (
        <Button size="sm" variant="warning" onClick={() => setShow(true)}>
          {t('common:remove')}
        </Button>
      )}
      <ConfirmModal show={show} onHide={() => setShow(false)} onConfirm={handleRemove} />
    </ListGroup.Item>
  );
};

export { ErrorItem };
