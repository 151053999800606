import { useMemo } from 'react';
import { object, date } from 'yup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { pick, keys } from 'lodash';

import { mergeInitialValues } from '@utils/formik';

import { FormValues } from './schema';
import { selectCarQuoteBuy } from '../selector';

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues: FormValues = {
    activationDate: '',
  };

  return useMemo(() => {
    if (!carQuote) return;

    return mergeInitialValues(initialValues, pick(carQuote, keys(initialValues)));
  }, [carQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    activationDate: date()
      .required(t('validation:required'))
      .min(moment().subtract(1, 'year'), 'must not exceed 1 year ago')
      .max(moment().add(3, 'month'), 'must not exceed 3 months from now'),
  });
};
