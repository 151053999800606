import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BackButton } from '@components/Base';
import HeaderSecondary from '@components/HeaderSecondary';
import { useCancerQuoteResultPageParams } from '@components/CancerQuoteResultPage';
import { getCancerQuoteResultPageRoute } from '@components/CancerQuoteResultPage/route';

import { useCancerQuotesFetch } from './hook';
import { TableInsurer } from './table-insurer';
import { TableAnnualLimit } from './table-annual-limit';
import { TableGeographicCoverage } from './table-geographic-coverage';
import { TableBenefit } from './table-benefit';

const CancerQuoteComparePage = () => {
  const navigate = useNavigate();
  const params = useCancerQuoteResultPageParams();
  const cancerQuotes = useCancerQuotesFetch();

  const handleBack = () => {
    navigate(getCancerQuoteResultPageRoute(params), { replace: true });
  };

  if (!cancerQuotes) return null;

  return (
    <>
      <HeaderSecondary />

      <Container className="mt-4">
        <BackButton onClick={handleBack} className="mb-4" />

        <Table>
          <tbody>
            <TableInsurer />
            <TableAnnualLimit />
            <TableBenefit />
            <TableGeographicCoverage />
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export { CancerQuoteComparePage };
