import humps from 'lodash-humps-ts';
import { omitBy, isArray } from 'lodash';
import { stringify } from 'qs';

import { API_V1_BASE } from '@config';
import { snakes } from '@utils/lodash';
import { authAxios } from '@components/Auth';
import { User } from '@models/User';
import { PersonalAccidentQuote, PersonalAccidentQuoteMember } from '@models/PersonalAccidentQuote';

export type Questionnaire = {
  question: string;
  answer: 'yes' | 'no';
};

type MePersonalDetails = Omit<User, 'id' | 'fullName' | 'fullNameKm'> & {
  age: number | '';
  questionnaire: Questionnaire[];
};

type MemberPersonalDetails = Omit<MePersonalDetails, 'gender' | 'email' | 'mobileNumber' | 'address'>;

export type PersonalAccidentQuotePeopleParams = {
  me: MePersonalDetails;
  spouse?: MemberPersonalDetails;
  sons?: MemberPersonalDetails[];
  daughters?: MemberPersonalDetails[];
};

export type GetAllPersonalAccidentQuoteParams = PersonalAccidentQuoteMember & {
  PersonalAccidentPlanIds?: number[];
};

export type GetPersonalAccidentQuoteParams = PersonalAccidentQuoteMember & {
  personalAccidentPlanId: number;
};

export type CreatePersonalAccidentQuoteParams = Pick<GetPersonalAccidentQuoteParams, 'personalAccidentPlanId'> &
PersonalAccidentQuotePeopleParams;

export type EditPersonalAccidentQuoteParams = {
  id: number;
};

export type UpdatePersonalAccidentQuoteParams = EditPersonalAccidentQuoteParams & PersonalAccidentQuotePeopleParams;

export type SendEmailPersonalAccidentQuoteParams = GetPersonalAccidentQuoteParams & {
  firstName: string;
  lastName: string;
  email: string;
};

export class PersonalAccidentQuoteApi {
  static getAll = async (params: GetAllPersonalAccidentQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/personal_accident_quotes`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote[]);
  };

  static get = async (params: GetPersonalAccidentQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/personal_accident_quotes/find`, {
        params: snakes(params),
        paramsSerializer: (parameters) => stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static create = async (params: CreatePersonalAccidentQuoteParams) => {
    return await authAxios
      .post(`${API_V1_BASE}/user/personal_accident_quotes`, {
        ...omitBy(snakes(params), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static edit = async ({ id }: EditPersonalAccidentQuoteParams) => {
    return await authAxios
      .get(`${API_V1_BASE}/user/personal_accident_quotes/${id}`)
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static update = async (params: UpdatePersonalAccidentQuoteParams) => {
    const { id, ...updateParams } = params;

    return await authAxios
      .put(`${API_V1_BASE}/user/personal_accident_quotes/${id}`, {
        ...omitBy(snakes(updateParams), (value) => isArray(value) && value.length === 0),
      })
      .then((response) => humps(response.data) as PersonalAccidentQuote);
  };

  static sendEmail = async (params: SendEmailPersonalAccidentQuoteParams) => {
    return await authAxios.post(`${API_V1_BASE}/personal_accident_quotes/send_email`, snakes(params));
  };
}
