import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '@components/Base';
import HealthQuotePremiumCardBody from '@components/HealthQuote/HealthQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';

import { healthOrderCreate, HealthOrderCreatePayload } from '../action';
import { getHealthQuoteBuyEditPageRoute } from '../route';
import { FeatureCardBody } from './feature-card-body';
import { BenefitCardBody } from './benefit-card-body';
import { MemberCardBody } from './member-card-body';

import { useHealthQuoteReview } from './hook';

const HealthQuoteReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { healthQuote, values } = useHealthQuoteReview();

  const handlePay = () => {
    if (!healthQuote) return null;

    const payload: HealthOrderCreatePayload = { healthQuoteId: healthQuote.healthOrderId };
    dispatch(healthOrderCreate(payload));
  };

  const handleEdit = () => {
    if (!healthQuote) return null;

    navigate({
      ...getHealthQuoteBuyEditPageRoute({ healthQuoteId: healthQuote.healthOrderId }),
      search: stringify({
        ...values,
        healthPlanId: healthQuote.healthPlanId,
      }),
    });
  };

  return (
    <>
      {isUndefined(healthQuote) && <Loading />}
      {healthQuote && (
        <Container className="my-5">
          <Row>
            <Col lg={7}>
              <Card>
                <Card.Header>{t('common:summary')}</Card.Header>
                <FeatureCardBody {...healthQuote} />
                <BenefitCardBody {...healthQuote} />
                <MemberCardBody {...healthQuote.people} onEdit={handleEdit} />
              </Card>
            </Col>
            <Col lg={5}>
              <Card className="mb-4">
                <HealthQuotePremiumCardBody {...healthQuote} />

                <Card.Body>
                  {!healthQuote.isValidMedicalQuestionnaire && (
                    <Alert variant="warning">{t('common:policyUnderReview')}</Alert>
                  )}

                  <Button
                    disabled={!healthQuote.isValidMedicalQuestionnaire}
                    onClick={handlePay}
                    className="btn-primary btn-lg w-100"
                    size="lg"
                  >
                    <span className="me-2">{t('common:payNow')}</span>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </Card.Body>
              </Card>

              <SupportCard />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { HealthQuoteReviewPage };
