import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { AnySchema } from 'yup';
import { useSelector } from 'react-redux';

import { FormGroupControl, } from '@components/Base';
import { getFields, isRequired } from '@utils/yup';
import { selectCurrentUser, selectIsCustomer } from '@components/Auth';

import { GenderFormSelect } from '../gender-form-select';
import { UserFormControlValues } from './schema';

const GROUP_PROPS = { className: 'mb-3' };

type Props = {
  validationSchema: AnySchema;
}

const UserFormControl = ({ validationSchema }: Props) => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const isCustomer = useSelector(selectIsCustomer);
  const { initialValues } = useFormikContext<UserFormControlValues>();

  if (!currentUser) return null;

  const fields = getFields(validationSchema) as UserFormControlValues;

  return (
    <>
      <Row>
        <Col>
          <FormGroupControl
            name="lastName"
            label={t('user.lastName')}
            required={isRequired(fields, "lastName")}
            disabled={isCustomer && !!initialValues.lastName}
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="firstName"
            label={t('user.firstName')}
            required={isRequired(fields, "firstName")}
            disabled={isCustomer && !!initialValues.firstName}
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroupControl
            name="lastNameKm"
            label={t('user.lastNameKm')}
            required={isRequired(fields, "lastNameKm")}
            disabled={isCustomer && !!initialValues.lastNameKm}
            groupProps={GROUP_PROPS}
          />
        </Col>
        <Col>
          <FormGroupControl
            name="firstNameKm"
            label={t('user.firstNameKm')}
            required={isRequired(fields, "firstNameKm")}
            disabled={isCustomer && !!initialValues.firstNameKm}
            groupProps={GROUP_PROPS}
          />
        </Col>
      </Row>

      <GenderFormSelect
        name="gender"
        required={isRequired(fields, "lastName")}
        disabled={isCustomer && !!initialValues.gender}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="personalCode"
        label={t('user.personalCode')}
        required={isRequired(fields, "personalCode")}
        disabled={isCustomer && !!initialValues.personalCode}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        type="date"
        name="dob"
        label={t('user.dob')}
        required={isRequired(fields, "dob")}
        disabled={isCustomer && !!initialValues.dob}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="occupation"
        label={t('user.occupation')}
        required={isRequired(fields, "occupation")}
        disabled={isCustomer && !!initialValues.occupation}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="email"
        label={t('user.email')}
        required={isRequired(fields, "email")}
        disabled={isCustomer && !!initialValues.email}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="mobileNumber"
        label={t('user.mobileNumber')}
        required={isRequired(fields, "mobileNumber")}
        disabled={!!initialValues.mobileNumber}
        groupProps={GROUP_PROPS}
      />
      <FormGroupControl
        name="address"
        label={t('user.address')}
        required={isRequired(fields, "address")}
        disabled={isCustomer && !!initialValues.address}
        as="textarea"
        rows={2}
        groupProps={GROUP_PROPS}
      />
    </>
  );
};

export { UserFormControl };
