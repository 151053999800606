import { createActions } from 'redux-actions';

import { CreateHealthOrderParams } from '@apis/HealthOrderApi';
import { CreateHealthQuoteParams, EditHealthQuoteParams, GetHealthQuoteParams, UpdateHealthQuoteParams } from '@apis/HealthQuoteApi';
import { HealthQuote } from '@models/HealthQuote';
import { HealthOrder } from '@models/HealthOrder';

export type State = {
  healthQuote: HealthQuote | null | undefined;
  healthOrder: HealthOrder | null | undefined;
};

export type HealthQuoteFetchPayload = GetHealthQuoteParams;
export type HealthQuoteCreatePayload = CreateHealthQuoteParams;
export type HealthQuoteEditPayload = EditHealthQuoteParams;
export type HealthQuoteUpdatePayload = UpdateHealthQuoteParams;

export type HealthOrderCreatePayload = CreateHealthOrderParams;

export type StateUpdatePayload = Partial<State>;

export const {
  healthQuoteFetch,
  healthQuoteCreate,
  healthQuoteEdit,
  healthQuoteUpdate,

  healthOrderCreate,

  stateUpdate,
} = createActions(
  'HEALTH_QUOTE_FETCH',
  'HEALTH_QUOTE_EDIT',
  'HEALTH_QUOTE_CREATE',
  'HEALTH_QUOTE_UPDATE',

  'HEALTH_ORDER_CREATE',

  'STATE_UPDATE',
  {
    prefix: 'HEALTH_QUOTE_BUY_PAGE',
  },
);
